var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "installmain" } }, [
    _c("h2", [_vm._v(" Pasos de instalación:")]),
    _c(
      "div",
      { attrs: { role: "tablist" } },
      [
        _c(
          "b-card",
          { staticClass: "mb-1", attrs: { "no-body": "" } },
          [
            _c("b-card-body", { staticClass: "p-1" }, [
              _c("div", { staticClass: "orderdata p-4" }, [
                _c("div", [
                  _c("div", { staticClass: "text-center m-auto" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("1. Enlace con Google Ads *")
                    ])
                  ])
                ]),
                _c("div", [
                  !_vm.step0
                    ? _c(
                        "div",
                        {
                          staticClass: "text-success text-center m-auto d-flex"
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Realizado")
                          ]),
                          _c("feather-icon", {
                            staticClass: "ml-2",
                            attrs: { icon: "CheckIcon" }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "text-danger text-center m-auto d-flex"
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Aún por realizar")
                          ]),
                          _c("feather-icon", {
                            staticClass: "ml-2",
                            attrs: { icon: "XIcon" }
                          })
                        ],
                        1
                      )
                ])
              ])
            ]),
            _c(
              "b-collapse",
              {
                staticClass: "border-top",
                model: {
                  value: _vm.step0,
                  callback: function($$v) {
                    _vm.step0 = $$v
                  },
                  expression: "step0"
                }
              },
              [
                _c(
                  "b-card-body",
                  [
                    _c("b-card-text", [
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            "Google Ads habrá enviado un correo a los administradores para enlazar la cuenta introducida con ClickDefense."
                          )
                        ]),
                        _c("ul", [
                          _c("li", { staticClass: "step2list" }, [
                            _vm._v(
                              "Abre el correo y sigue los pasos para aceptar la solicitud de enlace."
                            )
                          ])
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-card",
          { staticClass: "mb-1", attrs: { "no-body": "" } },
          [
            _c("b-card-body", { staticClass: "p-1" }, [
              _c("div", { staticClass: "orderdata p-4" }, [
                _c("div", [
                  _c("div", { staticClass: "text-center m-auto" }, [
                    _c(
                      "span",
                      {
                        staticClass: "font-weight-bold",
                        attrs: { id: "step2" }
                      },
                      [_vm._v("2. Activar el análisis o la protección *")]
                    )
                  ])
                ]),
                _c("div", [
                  !_vm.step1
                    ? _c(
                        "div",
                        {
                          staticClass: "text-success text-center m-auto d-flex"
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Realizado")
                          ]),
                          _c("feather-icon", {
                            staticClass: "ml-2",
                            attrs: { icon: "CheckIcon" }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "text-danger text-center m-auto d-flex"
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Aún por realizar")
                          ]),
                          _c("feather-icon", {
                            staticClass: "ml-2",
                            attrs: { icon: "XIcon" }
                          })
                        ],
                        1
                      )
                ])
              ])
            ]),
            _c(
              "b-collapse",
              {
                staticClass: "border-top",
                model: {
                  value: _vm.step1,
                  callback: function($$v) {
                    _vm.step1 = $$v
                  },
                  expression: "step1"
                }
              },
              [
                _c(
                  "b-card-body",
                  [
                    _c("b-card-text", [
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            'Tienes que seleccionar en la pestaña "Cuentas":'
                          )
                        ]),
                        _c("ul", [
                          _c("li", { staticClass: "step2list" }, [
                            _vm._v(
                              "Analizar, si quieres hacer un análisis del tráfico de los anuncios sin bloquear el fraude."
                            )
                          ]),
                          _c("li", { staticClass: "step2list" }, [
                            _vm._v(
                              "Proteger, si quieres bloquear el tráfico fraudulento de los anuncios y proteger la inversión."
                            )
                          ])
                        ]),
                        _c("span", [
                          _vm._v(
                            "Aparecerá realizado cuando recibamos los primeros clics de tu cuenta."
                          )
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-card",
          { staticClass: "mb-1", attrs: { "no-body": "" } },
          [
            _c("b-card-body", { staticClass: "p-1" }, [
              _c("div", { staticClass: "orderdata p-4" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "text-center m-auto",
                      attrs: { id: "step3" }
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("3. Activar el código de seguimiento ")
                      ]),
                      _c("span", [_vm._v("(Muy recomendado)")])
                    ]
                  )
                ]),
                _c("div", [
                  !_vm.step2
                    ? _c(
                        "div",
                        {
                          staticClass: "text-success text-center m-auto d-flex"
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Realizado")
                          ]),
                          _c("feather-icon", {
                            staticClass: "ml-2",
                            attrs: { icon: "CheckIcon" }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "text-danger text-center m-auto d-flex"
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Aún por realizar")
                          ]),
                          _c("feather-icon", {
                            staticClass: "ml-2",
                            attrs: { icon: "XIcon" }
                          })
                        ],
                        1
                      )
                ])
              ])
            ]),
            _c(
              "b-collapse",
              {
                staticClass: "border-top",
                model: {
                  value: _vm.step2,
                  callback: function($$v) {
                    _vm.step2 = $$v
                  },
                  expression: "step2"
                }
              },
              [
                _c("b-card-body", [
                  _c("div", [
                    _c("span", [
                      _vm._v(
                        "Muy recomendado para multiplicar la eficiencia del algoritmo:"
                      )
                    ]),
                    _c("ul", [
                      _c("li", { staticClass: "step2list" }, [
                        _vm._v("Sigue los pasos de nuestro "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://www.clickdefense.io/gtm-manual.html",
                              target: "_blank"
                            }
                          },
                          [_vm._v("manual")]
                        ),
                        _vm._v(" para instalarlo con Google Tag Manager.")
                      ]),
                      _c("li", { staticClass: "step2list" }, [
                        _vm._v(
                          "Para otro tipo de instalación manda un mensaje a soporte@clickdefense.io"
                        )
                      ])
                    ]),
                    _c("span", [
                      _vm._v(
                        " Aparecerá realizado pasados unos minutos. Si tienes algún problema escribe a soporte@clickdefense.io"
                      )
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }