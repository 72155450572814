<template>
  <div id="installmain">
    <h2> Pasos de instalación:</h2>
    <div role="tablist">
      <b-card no-body class="mb-1">
        <b-card-body class="p-1">
          <div class='orderdata p-4'>
            <div>
              <div class="text-center m-auto">
                <span class="font-weight-bold">1. Enlace con Google Ads *</span>
              </div>
            </div>
            <div>
              <div v-if="!step0" class="text-success text-center m-auto d-flex">
                <span class="font-weight-bold">Realizado</span>
                <feather-icon icon="CheckIcon" class="ml-2"></feather-icon>
              </div>
              <div v-else class="text-danger text-center m-auto d-flex">
                <span class="font-weight-bold">Aún por realizar</span>
                <feather-icon icon="XIcon" class="ml-2"></feather-icon>
              </div>
            </div>
          </div>
        </b-card-body>
        <b-collapse v-model="step0" class='border-top'>
          <b-card-body>
            <b-card-text>
              <div>
                <span>Google Ads habrá enviado un correo a los administradores para enlazar la cuenta introducida con ClickDefense.</span>
                <ul>
                  <li class="step2list">Abre el correo y sigue los pasos para aceptar la solicitud de enlace.</li>
                </ul>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-body class="p-1">
          <div class='orderdata p-4'>
            <div>
              <div class="text-center m-auto">
                <span class="font-weight-bold" id="step2">2. Activar el análisis o la protección *</span>
              </div>
            </div>
            <div>
              <div v-if="!step1" class="text-success text-center m-auto d-flex">
                <span class="font-weight-bold">Realizado</span>
                <feather-icon icon="CheckIcon" class="ml-2"></feather-icon>
              </div>
              <div v-else class="text-danger text-center m-auto d-flex">
                <span class="font-weight-bold">Aún por realizar</span>
                <feather-icon icon="XIcon" class="ml-2"></feather-icon>
              </div>
            </div>
          </div>
        </b-card-body>
        <b-collapse v-model="step1" class='border-top'>
          <b-card-body>
            <b-card-text>
              <div>
                <span>Tienes que seleccionar en la pestaña "Cuentas":</span>
                <ul>
                  <li class="step2list">Analizar, si quieres hacer un análisis del tráfico de los anuncios sin bloquear el fraude.</li>
                  <li class="step2list">Proteger, si quieres bloquear el tráfico fraudulento de los anuncios y proteger la inversión.</li>
                </ul>
                <span>Aparecerá realizado cuando recibamos los primeros clics de tu cuenta.</span>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-body class="p-1">
          <div class='orderdata p-4'>
            <div>
              <div class="text-center m-auto" id="step3">
                <span class="font-weight-bold">3. Activar el código de seguimiento </span>
                <span>(Muy recomendado)</span>
              </div>
            </div>
            <div>
              <div v-if="!step2" class="text-success text-center m-auto d-flex">
                <span class="font-weight-bold">Realizado</span>
                <feather-icon icon="CheckIcon" class="ml-2"></feather-icon>
              </div>
              <div v-else class="text-danger text-center m-auto d-flex">
                <span class="font-weight-bold">Aún por realizar</span>
                <feather-icon icon="XIcon" class="ml-2"></feather-icon>
              </div>
            </div>
          </div>
        </b-card-body>
        <b-collapse v-model="step2" class='border-top'>
          <b-card-body>
            <div>
              <span>Muy recomendado para multiplicar la eficiencia del algoritmo:</span>
              <ul>
                <li class="step2list">Sigue los pasos de nuestro <a href="https://www.clickdefense.io/gtm-manual.html" target="_blank">manual</a> para instalarlo con Google Tag Manager.</li>
                <li class="step2list">Para otro tipo de instalación manda un mensaje a soporte@clickdefense.io</li>
              </ul>
              <span> Aparecerá realizado pasados unos minutos. Si tienes algún problema escribe a soporte@clickdefense.io</span>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import Amplify from 'aws-amplify'
import { EventBus } from '@/event-bus'
import utils from '@/api/utils.js'

export default {
  data() {
    return {
      step0: false,
      step1: true,
      step2: true,
    }
  },
  methods: {
    async initialGetUserData(dataUserId) {
      await this.$initMainData(dataUserId)
    },
    onError: function () {
      return true
    },
  },
  mounted() {
    EventBus.$on('update-side-account', () => {
    })
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.initialGetUserData(this.$store.state.user.id)
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
    EventBus.$on('update-integrationStep', (integrationStep) => {
      this.step1 = !integrationStep
    })
    EventBus.$on('update-pixelEnabled', (pixelEnabled) => {
      this.step2 = !pixelEnabled
    })
  }
}
</script>
<style scoped>
.card {
  width: 85%;
  margin: auto;
  text-align: left;
}
.card-text {
  text-align: left;
}
#codetext {
  text-align: left;
  font-size: smaller;
}
.orderdata {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.step2list {
  list-style-type: disc;
  margin-left: 20px;
}
</style>
<style>
#installmain {
  text-align: center;
}
.container1links {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  color: #ffffff;
}
a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #007bff;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
}
a[class^="share-network-"] .iconfeather {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px;
  flex: 0 1 auto;
}
a[class^="share-network-"] span {
  padding: 0 5px;
  flex: 1 1 0%;
  font-weight: 400;
}
</style>